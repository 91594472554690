<template>
	<div class="container">

			<div class="row header align-end">
				<div class="col-6">
					<h3>Quizes</h3>
				</div>
				<div class="col-6 text-right">
					<a class="btn btn-brand shadow-none text-brand font-weight-bold text-decoration-underline" @click="goTo('QuestionBank')">Question Bank</a> <a class="btn btn-brand shadow-none text-brand font-weight-bold text-decoration-underline ml-5" @click="goTo('ResponseBank')">Response Bank</a> <a class="btn btn-brand shadow-none text-brand font-weight-bold text-decoration-underline ml-5" @click="goTo('NewQuiz')">New Quiz</a>
				</div>
			</div>

			<div class="brand-tabs">

				<v-tabs
			v-model="currentItem"
			ligth
			background-color="transparent"
			:slider-color="$store.getters.theme.mainAccentColor"
			slider-size="8"
			color="#000"
			class="brand-tabs-container"
		>
			<v-tab
				v-for="(tab, index) in tabs"
				:key="index"
				:href="`#${tab.component}`"
				class="organization-settings-tab-button primary-text"
				@click="currentItem = tab.component"
			>
				{{ tab.title }}
			</v-tab>
		</v-tabs>

		<div class="row my-4">
			<div class="col">
				SORT
			</div>
			<div class="col">
				SEARCH
			</div>
		</div>

		<v-tabs-items
			v-model="currentItem"
			class="organization-settings-tabs-container"
		>
			<v-tab-item
				v-for="tab in tabs"
				:key="tab.title"
				:value="tab.component"
				class="organization-settings-tab"
			>
				<keep-alive>
					<component :is="tab.component" />
				</keep-alive>
			</v-tab-item>
		</v-tabs-items>

			</div>
	</div>
	<!-- <v-container>
		<v-row>
			<v-col>
				<admin-default-header>Quizes</admin-default-header>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<admin-default-description
					>Maximize your learners’s success by incorporating quizzes
					into each course to test their
					knowledge.</admin-default-description
				>
			</v-col>
			<v-col class="d-flex flex-row align-start justify-end">
				<v-btn
					@click="goTo('QuestionBank')"
					class="admin-primary-button primary-contrast-background ml-4"
					:loading="isLoading"
					>Question Bank</v-btn
				>
				<v-btn
					@click="goTo('ResponseBank')"
					class="admin-primary-button primary-contrast-background ml-4"
					:loading="isLoading"
					>Response Bank</v-btn
				>
				<v-btn
					@click="goTo('NewQuiz')"
					class="admin-primary-button primary-contrast-background ml-4"
					:loading="isLoading"
					>New Quiz</v-btn
				>
			</v-col>
		</v-row>

		<v-tabs
			v-model="currentItem"
			ligth
			background-color="transparent"
			:slider-color="$store.getters.theme.mainAccentColor"
			slider-size="8"
			color="#000"
			class="organization-settings-tab-buttons-container"
		>
			<v-tab
				v-for="(tab, index) in tabs"
				:key="index"
				:href="`#${tab.component}`"
				class="organization-settings-tab-button primary-text"
				@click="currentItem = tab.component"
			>
				{{ tab.title }}
			</v-tab>
		</v-tabs>

		<v-tabs-items
			v-model="currentItem"
			class="organization-settings-tabs-container"
		>
			<v-tab-item
				v-for="tab in tabs"
				:key="tab.title"
				:value="tab.component"
				class="organization-settings-tab"
			>
				<keep-alive>
					<component :is="tab.component" />
				</keep-alive>
			</v-tab-item>
		</v-tabs-items>
	</v-container> -->
</template>

<script>
import AdminDefaultHeader from "@/components/AdminDefaultHeader.vue";
import QuizesAll from "./QuizesAll.vue";
import QuizesDeleted from "./QuizesDeleted.vue";
import AdminDefaultDescription from "@/components/AdminDefaultDescription.vue";
import { mapGetters } from "vuex";

export default {
	name: "Quizes",
	components: {
		QuizesAll,
		QuizesDeleted,
		AdminDefaultHeader,
		AdminDefaultDescription
	},
	computed: {
		...mapGetters(["isLoading"])
	},
	data() {
		return {
			templateName: "",
			currentItem: QuizesAll,
			tabs: [
				{
					title: "All",
					value: "all",
					component: "QuizesAll"
				},
				{
					title: "Deleted",
					value: "deleted",
					component: "QuizesDeleted"
				}
			]
		};
	},
	methods: {
		goTo(name, query) {
			this.$router.push({ name: name, query: query });
		}
	},
	created() {
		this.$store.dispatch("getQuizzes");
	}
};
</script>

<style></style>

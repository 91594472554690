var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tab-content"},[_c('table',{staticClass:"table table-striped brand draft-list"},[_vm._m(0),_c('tbody',_vm._l((_vm.allQuizzes.slice(0, _vm.limit)),function(quiz){return _c('RowData',{key:quiz.id,attrs:{"name":quiz.name,"dateLeft":`${new Date(
					quiz.created_at.slice(0, 10)
				).toLocaleDateString()} <br>Last Edited: ${Math.ceil(
					Number(new Date() - new Date(quiz.updated_at)) /
						1000 /
						60 /
						60 /
						24
				)} days ago`,"buttons":[
					{
						title: 'Analytics',
						action: () => _vm.showAnalytics(quiz.id)
					},
					{ title: 'Delete', action: () => _vm.deleteQuiz(quiz.id) },
					{
						title: 'Duplicate',
						action: () => _vm.duplicateQuiz(quiz)
					},
					{
						title: 'Edit',
						action: () => _vm.editQuiz(quiz.id)
					}
				]}})}),1)]),(_vm.allQuizzes.length > _vm.limit)?_c('v-btn',{staticClass:"admin-primary-button secondary-contrast-background",attrs:{"loading":_vm.isLoading},on:{"click":_vm.loadMoreQuizzes}},[_vm._v("Load More")]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"col-2"}),_c('th',{staticClass:"col-3"},[_vm._v("Quize Title")]),_c('th',{staticClass:"col-2 text-center"},[_vm._v("Date Created")]),_c('th',{staticClass:"col-6"})])])
}]

export { render, staticRenderFns }
<template>
	<div class="tab-content">
		<table class="table table-striped brand draft-list">
			<thead>
				<tr>
					<th class="col-2"></th>
					<th class="col-3">Quize Title</th>
					<th class="col-2 text-center">Date Created</th>
					<th class="col-6"></th>
				</tr>
			</thead>
			<tbody>

				<RowData
					v-for="quiz in allQuizzes.slice(0, limit)"
					:key="quiz.id"
					:name="quiz.name"
					:dateLeft="`${new Date(
						quiz.created_at.slice(0, 10)
					).toLocaleDateString()} <br>Last Edited: ${Math.ceil(
						Number(new Date() - new Date(quiz.updated_at)) /
							1000 /
							60 /
							60 /
							24
					)} days ago`"

					:buttons="[
						{
							title: 'Analytics',
							action: () => showAnalytics(quiz.id)
						},
						{ title: 'Delete', action: () => deleteQuiz(quiz.id) },
						{
							title: 'Duplicate',
							action: () => duplicateQuiz(quiz)
						},
						{
							title: 'Edit',
							action: () => editQuiz(quiz.id)
						}
					]"
				/>

			</tbody>
		</table>

		<v-btn
					v-if="allQuizzes.length > limit"
					class="admin-primary-button secondary-contrast-background"
					:loading="isLoading"
					@click="loadMoreQuizzes"
					>Load More</v-btn
				>
	</div>
	<!-- <v-container>
		<v-row>
			<v-col>
				<admin-default-subheader>My Quizes</admin-default-subheader>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<RowData
					v-for="quiz in allQuizzes.slice(0, limit)"
					:key="quiz.id"
					:name="quiz.name"
					:dateLeft="`Last Edited: ${Math.ceil(
						Number(new Date() - new Date(quiz.updated_at)) /
							1000 /
							60 /
							60 /
							24
					)} days ago`"
					:dateRight="`Date Created: ${new Date(
						quiz.created_at.slice(0, 10)
					).toLocaleDateString()}`"
					:buttons="[
						{
							title: 'Analytics',
							action: () => showAnalytics(quiz.id)
						},
						{ title: 'Delete', action: () => deleteQuiz(quiz.id) },
						{
							title: 'Duplicate',
							action: () => duplicateQuiz(quiz)
						},
						{
							title: 'Edit',
							action: () => editQuiz(quiz.id)
						}
					]"
				/>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="d-flex flex-column align-center">
				<v-btn
					v-if="allQuizzes.length > limit"
					class="admin-primary-button secondary-contrast-background"
					:loading="isLoading"
					@click="loadMoreQuizzes"
					>Load More</v-btn
				>
			</v-col>
		</v-row>
	</v-container> -->
</template>

<script>
import AdminDefaultSubheader from "@/components/AdminDefaultSubheader.vue";
import { mapGetters } from "vuex";
import { remove } from "@/util/requests/remove";
import { post } from "@/util/requests/post";
import { get } from "@/util/requests/get";

export default {
	name: "CoursesDrafts",
	components: { AdminDefaultSubheader },
	computed: {
		...mapGetters(["allQuizzes", "isLoading", "organization"])
	},
	data() {
		return {
			limit: 2
		};
	},
	methods: {
		loadMoreQuizzes() {
			this.limit = this.allQuizzes.length;
		},
		editQuiz(id) {
			this.$router.push({
				name: "QuizEditor",
				params: { id: id }
			});
		},
		async deleteQuiz(id) {
			try {
				await this.$store.dispatch("setIsLoading", true);
				const response = await remove(`/quiz/${id}`);

				if (response.status === 200) {
					this.$store.dispatch("getQuizzes");
				}
			} catch (error) {
				this.$emit("setMessage", error.response.data.message);
			}
			await this.$store.dispatch("setIsLoading", false);
		},
		async saveQuestion(
			question,
			isQuizQuestion,
			duplicatedQuiz,
			response,
			newQuestion
		) {
			const payload = {
				label: question.label,
				content: question.content,
				help_html: question.help_html,
				help_text: question.help_text,
				type: question.type,
				required: question.required,
				sort_order: question.sort_order,
				custom: question.custom,
				organization_id: question.organization_id,
				category_id: question.category_id
			};
			const {
				data: { data }
			} = await post("/question", payload);

			if (isQuizQuestion === true) {
				await this.saveQuizQuestion(data, duplicatedQuiz.id);
			} else {
				await this.saveCondQuestion(newQuestion, data, response);
			}
			return data;
		},
		async saveResponse(response, question) {
			const payload = {
				question_id: question.id,
				content: response.content,
				help_text: response.help_text,
				sort_order: response.sort_order,
				explanation: response.explanation,
				risk_score: response.risk_score,
				custom: response.custom,
				organization_id: response.organization_id,
				category_id: response.category_id
			};
			const {
				data: { data }
			} = await post("/response", payload);
			return data;
		},
		async saveQuizQuestion(data, quizId) {
			await post("/quiz_question", {
				quiz_id: quizId,
				question_id: data.id,
				custom: false,
				organization_id: this.organization.id,
				category_id: "1"
			});
		},
		async saveCondQuestion(triggeredQuestion, data, response) {
			const { data: parentData } = await post(
				"/question_condition_parent",
				{
					question_id: triggeredQuestion.id,
					response_id: response.id,
					operator: "",
					response_text: "response"
				}
			);
			await post("/question_condition_child", {
				question_id: data.id,
				question_condition_parent_id: parentData.data.id,
				custom: Number(false),
				organization_id: 1,
				category_id: 1
			});
		},
		async duplicateQuiz(originalQuiz) {
			try {
				await this.$store.dispatch("setIsLoading", true);
				const duplicatedQuiz = await post("/quiz", {
					name: originalQuiz.name,
					start_button_text: originalQuiz.start_button_text,
					started_heading: originalQuiz.started_heading,
					started_html: originalQuiz.started_html,
					completed_heading: originalQuiz.completed_heading,
					completed_html: originalQuiz.completed_html,
					sort: originalQuiz.sort,
					custom_course_id: originalQuiz.custom_course_id,
					type: originalQuiz.type,
					organization_id: this.organization.id,
					custom: originalQuiz.custom,
					category_id: originalQuiz.category_id
				});

				const {
					data: { data: quizData }
				} = await get(`/quiz/${originalQuiz.id}`);
				if (!!quizData.questions === false) return;

				await quizData.questions.map(async ({ question }) => {
					const newQuestion = await this.saveQuestion(
						question,
						true,
						duplicatedQuiz.data.data
					);
					await Promise.all(
						question.responses.map(async response => {
							const newCondResponse = await this.saveResponse(
								response,
								newQuestion
							);
							if (response.cond_question !== null) {
								await Promise.all(
									await response.cond_questions.map(
										async ({ question }) => {
											const condQuestion =
												await this.saveQuestion(
													question,
													false,
													duplicatedQuiz.data.data,
													newCondResponse,
													newQuestion
												);
											await Promise.all(
												question.responses.map(
													async response => {
														await this.saveResponse(
															response,
															condQuestion
														);
													}
												)
											);
										}
									)
								);
							}
						})
					);
				});

				await this.$store.dispatch("getQuizzes");
			} catch (error) {
				await this.$store.dispatch("setIsLoading", false);
				this.$emit("setMessage", error.response.data.message);
			}
			await this.$store.dispatch("setIsLoading", false);
		},
		showAnalytics(id) {
			this.$router.push({
				name: "QuizAnalytics",
				params: { id: id }
			});
		}
	}
};
</script>

<style></style>
